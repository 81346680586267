import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { format, distanceInWords, differenceInDays } from "date-fns";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../../lib/helpers";
import Container from "../../components/container";
import GraphQLErrorList from "../../components/graphql-error-list";
import SEO from "../../components/seo";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { ContainerFullWidth, ContainerMain, Backdrop, RelatedPagesPanel, RelatedPagesGroup, PanelLabel, ContainerTwoCol, ContainerContent } from "../../containers";
import Hero from "../../components/hero";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import NumberFormat from "react-number-format";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { MdHelpOutline, MdHelp, MdCheck, MdWarning, MdInfo, MdInfoOutline } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'

const H1 = styled.h1`
  @media (max-width: 500px) {
    font-size: 1.4rem;
  }
`;

const H2 = styled.h2`
  font-weight: 300;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

const CalculatorGrid = styled.div`
  margin: 0 auto;
  padding: 24px;
  max-width: 1200px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};
  background-color: ${(props) => props.theme.theme.bg.primary};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(350px, 1fr) 4fr;
  grid-gap: 48px;
  align-items: start;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`;

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0px 0 0 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`;

const NeutralTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0px 0 0 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover {
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${(props) => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`;

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  svg {
    margin: 0 0 0 4px;
  }
`;

const GroupLabel = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: uppercase;
`;

const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`;

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`;

const TooltipPanel = styled.div`
  background-color: ${(props) => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`;

const InputSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 12px 12px 12px;
  border-left: 2px solid;
  /* border-radius: 8px; */
  margin: 0 0 24px 0;
`;

const InputSectionGreen = styled(InputSection)`
  border-color: ${(props) => props.theme.theme.colors.green};

  .GroupLabel {
    color: ${(props) => props.theme.theme.colors.green};
  }
`;

const InputSectionBlue = styled(InputSection)`
  border-color: ${(props) => props.theme.theme.colors.blue};

  .GroupLabel {
    color: ${(props) => props.theme.theme.colors.blue};
  }
`;

const InputGroup = styled.div`
  box-sizing: border-box;
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  border-radius: 8px;
  padding: 12px 12px 0px 12px;
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ; */
  /* grid-gap: 24px; */
  margin: 0 0 12px 0;

  h3 {
    color: ${(props) => props.theme.theme.text.primary};
    text-transform: uppercase;
    margin: 0 0 12px 0;
    font-weight: 500;
  }

  @media (max-width: 500px) {
    /* padding: 24px 0px 0 0px; */
  }
`;

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${(props) => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`;

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;
`;

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
  width: 100%;
`;

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0 12px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const AllocationGroupRed = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentRed};
  border: 1px solid ${(props) => props.theme.theme.colors.red};
  color: ${(props) => props.theme.theme.colors.red};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.red};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.red};
  }
`;

const AllocationGroupGreen = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  color: ${(props) => props.theme.theme.colors.green};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const AllocationGroupPurple = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentPurple};
  border: 1px solid ${(props) => props.theme.theme.colors.purple};
  color: ${(props) => props.theme.theme.colors.purple};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.purple};
  }
`;

const AllocationGroupOrange = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentOrange};
  border: 1px solid ${(props) => props.theme.theme.colors.orange};
  color: ${(props) => props.theme.theme.colors.orange};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.orange};
  }
`;

const AllocationGroupBlue = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentBlue};
  border: 1px solid ${(props) => props.theme.theme.colors.blue};
  color: ${(props) => props.theme.theme.colors.blue};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.blue};
  }
`;

const AllocationGroupBlack = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.colors.black};
  color: ${(props) => props.theme.theme.colors.black};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.black};
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }
`;

const AllocationGroupRow = styled.div`
  display: grid;
  grid-template-columns: 90px 100px 60px;
  grid-gap: 18px;
`;

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const AllocationMessage = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  width: 225px;
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 12px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0px;
  }
`;

const ResultsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  @media (max-width: 500px) {
  }
`;

const NotePanel = styled(AllocationGroup)`
  color: ${(props) => props.theme.theme.text.tertiary};
  padding: 12px;
  display: grid;
  align-items: center;
  grid-template-columns: 25px 1fr;

  svg {
    margin: 0 4px 0 0;
  }
`;

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const CustomTooltip = (props) => {
  const { active } = props;

  if (active) {
    const { payload, label } = props;
    console.log(payload);
    console.log(label);

    return (
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month: </strong>
          {payload[0].payload.month}
        </CardText400>
        <CardText400>
          <strong>Emergency fund remaining: </strong>
          <NumberFormat
            displayType={"text"}
            value={payload[0].payload.savingsValue.toFixed(0)}
            thousandSeparator
            prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    );
  }
  return null;
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const EmergencyFundCalc = () => {

  const [currentAge, setCurrentAge] = useState(30);
  const [currentTakeHomePay, setCurrentTakeHomePay] = useState(60000);
  const [currentSalary, setCurrentSalary] = useState(85000);
  const [currentAnnualSpending, setCurrentAnnualSpending] = useState(30000);
  const [currentNetWorth, setCurrentNetWorth] = useState(100000);
  const [graphEndAge, setGraphEndAge] = useState(67);
  const [annualSpend, setAnnualSpend] = useState(30000);
  const [investmentRate, setInvestmentRate] = useState(7);
  const [stocksRate, setStocksRate] = useState(8);
  const [stocksAllocation, setStocksAllocation] = useState(90);
  const [bondsRate, setBondsRate] = useState(5);
  const [bondsAllocation, setBondsAllocation] = useState(0);
  const [cashRate, setCashRate] = useState(0.5);
  const [cashAllocation, setCashAllocation] = useState(10);
  const [miscRate, setMiscRate] = useState(5);
  const [miscAllocation, setMiscAllocation] = useState(0);
  const [sideIncomeMonthly, setSideIncomeMonthly] = useState(0);
  const [sideIncomeAgeRange, setSideIncomeAgeRange] = useState([50, 60]);
  const [companyMatch, setCompanyMatch] = useState(0);
  const [incomeGrowthRate, setIncomeGrowthRate] = useState(3);

  const [inflationRate, setInflationRate] = useState(3);
  const [SWR, setSWR] = useState(4);
  const [payment, setPayment] = useState(500);
  const [annualCompoundingPeriods, setAnnualCompoundingPeriods] = useState(1);
  const [annualPaymentPeriods, setAnnualPaymentPeriods] = useState(1);

  const [housingSpend, setHousingSpend] = useState(1000);
  const [transportationSpend, setTransportationSpend] = useState(300);
  const [foodSpend, setFoodSpend] = useState(300);
  const [insuranceSpend, setInsuranceSpend] = useState(400);
  const [debtRepay, setDebtRepay] = useState(500);
  const [miscSpend, setMiscSpend] = useState(400);
  const [runwayLength, setRunwayLength] = useState(6);

  // console.log(`stocksAllocation: ${stocksAllocation}`)
  // console.log(`stocksRate: ${stocksRate}`)
  // console.log(`bondsAllocation: ${bondsAllocation}`)
  // console.log(`bondsRate: ${bondsRate}`)
  // console.log(`cashAllocation: ${cashAllocation}`)
  // console.log(`cashRate: ${cashRate}`)
  // console.log(`miscAllocation: ${miscAllocation}`)
  // console.log(`miscRate: ${miscRate}`)

  const totalSpending = parseFloat(housingSpend) + parseFloat(transportationSpend) + parseFloat(foodSpend) + parseFloat(insuranceSpend) + parseFloat(debtRepay) + parseFloat(miscSpend)
  const emergencyFundAmt = totalSpending * runwayLength

  const totalAllocation =
    parseFloat(stocksAllocation) +
    parseFloat(bondsAllocation) +
    parseFloat(cashAllocation) +
    parseFloat(miscAllocation);

  const weightedAverageRate =
    (stocksRate * stocksAllocation +
      bondsRate * bondsAllocation +
      cashRate * cashAllocation +
      miscRate * miscAllocation) /
    totalAllocation;

  const rate =
    Math.pow(
      1 + (weightedAverageRate - inflationRate) / 100 / annualCompoundingPeriods,
      annualCompoundingPeriods / annualPaymentPeriods
    ) - 1;

    console.log(`rate: ${rate}`)

  const fireNumber = annualSpend / (0.01 * SWR);

  let fireAge = 0;

  const calcEmergencyFundData = () => {
    let emergencyFundData = [];

    for (let i = 0; i <= runwayLength; i++) {

      emergencyFundData.push({
        month: i,
        savingsValue: emergencyFundAmt * ( 1 - (i/runwayLength) )
      })
    }
    return emergencyFundData;
  };

  const emergencyFundData = calcEmergencyFundData();

  console.log(emergencyFundData);

  return (
    <Layout>
      <SEO
        title={"Emergency Fund Calculator"}
        description={"How much cash should you have saved up in case of an emergency?"}
      />
      <CustomReactTooltip />

      <CalculatorGrid>
        <form>
          <InputGroup>
          <Label>Your Expenses</Label>
            <FlexInput>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Housing"
                  value={housingSpend}
                  onChange={(event) => setHousingSpend(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your monthly housing-related spending.
                  This could be your total mortgage payment or your rent plus utilities.`}
                />
              </InputItem>
            </FlexInput>
            <FlexInput>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Transportation"
                  value={transportationSpend}
                  onChange={(event) => setTransportationSpend(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your monthly transportation-related spending.
                  This includes car payments, gas, ridesharing and public transit passes.`}
                />
              </InputItem>
            </FlexInput>
            <FlexInput>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Food"
                  value={foodSpend}
                  onChange={(event) => setFoodSpend(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp size="1.2rem" data-tip={`This is your monthly food-related spending.
                This includes groceries, coffee, and restaurant bills.`} />
              </InputItem>
            </FlexInput>

            <FlexInput>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Insurance"
                  value={insuranceSpend}
                  onChange={(event) => setInsuranceSpend(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your monthly insurance-related spending.
                    This includes auto, home, and medical/eye/dental insurance.`}
                />
              </InputItem>
            </FlexInput>

            <FlexInput>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Debt Repayment"
                  value={debtRepay}
                  onChange={(event) => setDebtRepay(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is all monthly spending that goes towards paying off debts.
                  This includes paying off debt not covered in the other input fields like credit card debt and student loans.`}
                />
              </InputItem>
            </FlexInput>

            <FlexInput>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Other Spending"
                  value={miscSpend}
                  onChange={(event) => setMiscSpend(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is all other monthly spending not covered by the other input categories that you could NOT cut out if you lost your job.
                  This includes any recurring payments/subscriptions you may have like a phone plan, gym membership, Netflix, Spotify, etc.`}
                />
              </InputItem>
            </FlexInput>

          </InputGroup>

            <AllocationGroupGray>
              <Label>
                Runway Length (in Months)
                <MdHelp
                  size="1.2rem"
                  data-tip="This is how long you would like your emergency fund to last. 6 months is the minimum recommended runway length.
                  This number could be reduced if you work in a stable/high demand field.
                  If you work in a field where it may take longer to find a job, consider increasing your runway beyond 6 months."
                />
              </Label>
              <AllocationGroupRowTwoCol>
                <SliderBlack
                  value={runwayLength}
                  aria-labelledby="discrete-slider-always"
                  step={1}
                  min={0}
                  max={24}
                  marks={[
                    {
                      value: 6,
                      label: "6",
                    },
                    {
                      value: 12,
                      label: "12",
                    },
                    {
                      value: 24,
                      label: "24",
                    },
                  ]}
                  valueLabelDisplay="auto"
                  onChange={(event, value) => setRunwayLength(value)}
                />

                <ThemedTextarea
                  id="outlined-name"
                  label="Months"
                  value={runwayLength}
                  onChange={(event) => setRunwayLength(event.target.value)}
                  variant="filled"
                  size="small"
                  fullWidth
                />
              </AllocationGroupRowTwoCol>
            </AllocationGroupGray>


          <ResultsPanel>
            <ResultsGroup>
              <span className="label">Monthly spending: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={totalSpending.toFixed(0)}
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </ResultsGroup>

            <ResultsGroup>
              <span className="label">Emergency Fund: </span>
              <span className="result-value">
                <NumberFormat
                    displayType={"text"}
                    value={emergencyFundAmt.toFixed(0)}
                    thousandSeparator
                    prefix="$"
                />
              </span>
            </ResultsGroup>
          </ResultsPanel>
        </form>

        <GraphDesktop>
          <ResponsiveContainer width="100%" height={500}>
            <AreaChart data={emergencyFundData} margin={{ top: 10, right: 0, left: 50, bottom: 30 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                label={{ value: "Month", position: "insideBottom", offset: -10 }}
              />
              <YAxis
                tickFormatter={(tick) => {
                  return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                }}
              />
              {/* <Tooltip/> */}
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="top" height={-20} />
              {/* <Area type='monotone' name="Coast FIRE number" dataKey='theoreticalNetWorth' stroke='#1274E0' fill='#CCE4FF' /> */}
              {/* <Area type='monotone' name="Net worth with continued contributions" dataKey='continuousGrowthNetWorth' stroke='#b4b4b4' strokeDasharray="10 10" fillOpacity={0} /> */}
              <Area
                type="monotone"
                name="Emergency Fund Remaining"
                dataKey="savingsValue"
                stroke="#3AC732"
                fill="#CFF0CD"
              />
            </AreaChart>
          </ResponsiveContainer>
        </GraphDesktop>

        <GraphMobile>
          <ResponsiveContainer width="100%" height={500}>
            <AreaChart data={emergencyFundData} margin={{ top: 10, right: 0, left: 10, bottom: 30 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                label={{ value: "Month", position: "insideBottom", offset: -10 }}
              />
              <YAxis
                tickFormatter={(tick) => {
                  return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="top" height={36} />
              <Area
                type="monotone"
                name="Emergency Fund Remaining"
                dataKey="savingsValue"
                stroke="#3AC732"
                fill="#CFF0CD"
              />
            </AreaChart>
          </ResponsiveContainer>
        </GraphMobile>

      </CalculatorGrid>

    </Layout>
  );
};

export default EmergencyFundCalc;
